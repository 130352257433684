<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1024 1024"
    xml:space="preserve"
  >
    <title>Attractions of Vietnam</title>
    <path
      class="fill-secondary-700 dark:fill-secondary-400"
      d="M327.6,486.7c-58.3-28.6-128.7-4.5-157.3,53.7L18.2,854.6C-10.3,913,13.8,983.4,72.2,1012s128.8,4.4,157.4-53.9
	L381.6,644c28.5-58.3,4.4-128.7-53.9-157.2C327.7,486.8,327.6,486.7,327.6,486.7z"
    />
    <path
      class="fill-secondary-700 dark:fill-secondary-400"
      d="M1008,854.6L621.1,65.8c-28.6-58.3-99-82.3-157.2-53.8s-82.3,99-53.8,157.2L797,958.1
	c28.6,58.3,99,82.3,157.2,53.8C1012.5,983.3,1036.5,912.9,1008,854.6z"
    />
    <circle   class="fill-secondary-700 dark:fill-secondary-400" cx="512" cy="864" r="160"/>
  </svg>
</template>
