
import * as glideRuntime$ZY3BxIf3w3 from 'd:/App/Attractions/nuxt-app/node_modules/@nuxt/image/dist/runtime/providers/glide'
import * as storageRuntime$IjPthuu3SM from 'd:/App/Attractions/nuxt-app/providers/storage.js'
import * as s3Runtime$S0xJbOwqKW from 'd:/App/Attractions/nuxt-app/providers/s3.js'
import * as ipxRuntime$g7lvqWv2lA from 'd:/App/Attractions/nuxt-app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['glide']: { provider: glideRuntime$ZY3BxIf3w3, defaults: {"baseURL":"https://storage.attractionsvietnam.com"} },
  ['storage']: { provider: storageRuntime$IjPthuu3SM, defaults: {"baseURL":"https://storage.attractionsvietnam.com/storage","placeholder":"/icons/loading.svg"} },
  ['s3']: { provider: s3Runtime$S0xJbOwqKW, defaults: {"baseURL":""} },
  ['ipx']: { provider: ipxRuntime$g7lvqWv2lA, defaults: {} }
}
        