<template>
  <div class="flex items-center text-primary-600 dark:text-neutral-50 gap-x-4">
    <a href="https://www.facebook.com/attractionsvietnam/" rel="nofollow external" title="facebook" target="_blank">
      <Icon name="bxl:facebook-circle" size="28" />
    </a>
    <a href="https://www.instagram.com/planet.attractions" rel="nofollow external" target="_blank" title="instagram">
      <Icon name="bxl:instagram-alt" size="28" />
    </a>
    <a href="https://twitter.com/AttractionsVn" rel="nofollow external" target="_blank" title="twitter">
      <Icon name="bxl:twitter" size="28" />
    </a>
    <a href="https://www.tiktok.com/@planetexplorerr" rel="nofollow external" target="_blank" title="tiktok">
      <Icon name="bxl:tiktok" size="28" />
    </a>
    <a href="https://www.youtube.com/@planetexplorerr" rel="nofollow external" target="_blank" title="youtube">
      <Icon name="bxl:youtube" size="28" />
    </a>
  </div>
</template>
<script></script>
