<template>
  <div id="footer">
    <footer
      class="relative pt-16 lg:pt-28 border-t border-neutral-200 dark:border-neutral-700"
    >
      <div
        class="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:gap-x-10"
      >
        <div class="text-sm col-span-2">
          <h2 class="font-bold text-lg text-neutral-900 dark:text-neutral-200">
            {{ $t("best_of_attraction") }}
          </h2>
          <ul class="mt-6 gap-y-4 flex flex-wrap">
            <li v-for="place in places" class="w-full lg:w-1/2">
              <NuxtLink
                class="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white line-clamp-1"
                :to="localePath('/place/' + place.slug)"
              >
                {{ $t("travel_to", { place: place.name }) }}
              </NuxtLink>
            </li>
          </ul>
        </div>

        <div class="md:col-span-1 col-span-2">
          <V2FooterResource></V2FooterResource>
        </div>
        <div class="text-sm md:col-span-1 col-span-2">
          <V2FooterNewsletter></V2FooterNewsletter>
        </div>
      </div>
      <div
        class="relative border-t border-gray-200 dark:border-neutral-700 mt-16 lg:mt-28 py-4 dark:bg-slate-900"
      >
        <div class="container">
          <div
            class="sm:relative flex flex-col items-center gap-3 sm:flex-row sm:justify-between"
          >
            <div class="text-xs">
              © {{ $dayjs().format("YYYY") }} AV - Attractions Vietnam.
              {{ $t("all_rights_reserved") }}
            </div>
            <div class="mx-auto">
              <a
                href="//www.dmca.com/Protection/Status.aspx?ID=3e2ce296-b6d3-48f8-8853-99fdb7fd4bd5"
                title="DMCA.com Protection Status"
                target="_blank"
                class="dmca-badge"
              >
                <img
                  src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-06.png?ID=3e2ce296-b6d3-48f8-8853-99fdb7fd4bd5"
                  alt="DMCA.com Protection Status"
              /></a>
              <ClientOnly>
                <component
                  :is="'script'"
                  src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"
                ></component>
              </ClientOnly>
            </div>
            <LayoutsSocial />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script lang="ts" setup>
import type { CategoryShort } from "~/types/Post";
import type { HotelShort, Place } from "~/types/place";

const localePath = useLocalePath();
const props = defineProps({
  places: {
    type: Array as () => Place[],
    default: [],
  },
  categories: {
    type: Array as () => CategoryShort[],
    default: [],
  },
  hotels: {
    type: Array as () => HotelShort[],
    default: [],
  },
});
const dmca_id = useRuntimeConfig().public.dmca_id;
</script>
