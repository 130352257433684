<template>
  <div>
    <NuxtLink class="inline-block text-primary-600" :to="localePath('/')">
      <IconLogo  class="h-8 md:h-12"/>
    </NuxtLink>
  </div>
</template>
<script lang="ts" setup>
const localePath = useLocalePath();
</script>
