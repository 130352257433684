<template>
  <TransitionRoot appear as="template" :show="mobile_collapsed">
    <div class="z-[999] relative">
      <div
        class="fixed inset-0 bg-neutral-900 bg-opacity-50 opacity-100"
        @click="mobile_collapsed = false"
      ></div>
      <TransitionChild
        enter="transform ease-in-out transition-transform duration-300"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transform ease-in-out transition-transform duration-300"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
        as="template"
      >
        <div
          class="fixed inset-y-0 left-0 w-screen max-w-sm overflow-y-auto z-50"
        >
          <div class="flex min-h-full">
            <div class="w-full max-w-sm overflow-hidden transition-all">
              <div
                ref="el"
                class="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800"
              >
                <div class="py-6 px-5">
                  <ElementsLogo />
                  <div class="absolute right-2 top-2 p-1 z-50">
                    <button
                      @click="mobile_collapsed = false"
                      class="w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none"
                    >
                      <IconX class="w-5 h-5" />
                      <span class="sr-only">Close</span>
                    </button>
                  </div>
                  <div
                    @click="OpenSearchModal"
                    class="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1 py-1 px-4 mt-4 rounded-xl h-full"
                  >
                    <IconSearch class="w-6 dark:text-white" />
                    <input
                      type="search"
                      readonly
                      :placeholder="$t('what_looking')"
                      class="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm"
                    />
                  </div>
                </div>
                <ul class="flex flex-col py-6 px-2 space-y-1">
                  <LayoutsMobileItem :title="$t('home')" :path="'/'" />
                  <LayoutsMobileGroup>
                    <template #title>
                      <span>{{ $t("destinations") }}</span>
                    </template>
                    <template #menu-child>
                      <LayoutsMobileChildItem
                        v-for="place in places"
                        :title="place.name"
                        :path="'/place/' + place.slug"
                      />
                    </template>
                  </LayoutsMobileGroup>
                  <LayoutsMobileItem :title="'Blog'" :path="'/blog'" />
                  <LayoutsMobileItem
                    :title="$t('contact_us')"
                    :path="'/contact-us'"
                  />
                </ul>
                <div class="py-6 px-2">
                  <ul class="flex-col space-y-1">
                    <li class="flex items-center">
                      <span
                        class="router-link-active router-link-exact-active flex w-full items-center py-2.5 px-4 font-medium tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 dark:text-white rounded-lg"
                        >{{ $t("language") }}</span
                      >
                      <select
                        @change="ChangeLocale"
                        class="h-10 w-fit pr-8 text-sm rounded-lg text-neutral-900 dark:bg-neutral-800 dark:text-neutral-200 font-medium border-none"
                      >
                        <option value="vi" :selected="locale == 'vi'">
                          Tiếng việt
                        </option>
                        <option value="en" :selected="locale == 'en'">
                          English
                        </option>
                      </select>
                    </li>
                    <li class="flex items-center">
                      <span
                        class="router-link-active router-link-exact-active flex w-full items-center py-2.5 px-4 font-medium tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 dark:text-white rounded-lg"
                        >{{ $t("currency") }}</span
                      >
                      <select
                        @change="ChangeCurrency"
                        class="h-10 w-fit pr-8 text-sm rounded-lg text-neutral-900 dark:bg-neutral-800 dark:text-neutral-200 font-medium border-none"
                      >
                        <option value="VND" :selected="currency == 'VND'">
                          ₫ Đồng
                        </option>
                        <option value="USD " :selected="currency == 'USD'">
                          $ Dollar
                        </option>
                      </select>
                    </li>
                    <li class="flex items-center">
                      <span
                        class="router-link-active router-link-exact-active flex w-full items-center py-2.5 px-4 font-medium tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 dark:text-white rounded-lg"
                        >{{ $t("dark_mode") }}</span
                      >
                      <LayoutsColorMode />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TransitionChild>
    </div>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { useConfigStore } from "~/stores/config";
import type { Place } from "~/types/place";
import type { Attraction } from "~/types/Attractions";

const { locale, setLocale } = useI18n();
const { mobile_collapsed, search_modal } = storeToRefs(useConfigStore());
import type { UseSwipeDirection } from "@vueuse/core";
import { useCurrencyStore } from "~/stores/currency";

const props = defineProps({
  places: {
    type: Array as () => Place[],
    default: [],
  },
  attractions: {
    type: Array as () => Attraction[],
    default: [],
  },
});
const OpenSearchModal = () => {
  mobile_collapsed.value = false;
  useRouter().push(useLocalePath()("/"));
};
const ChangeLocale = (e: Event) => {
  const val = (e.target as HTMLInputElement).value;
  setLocale(val);
  mobile_collapsed.value = false;
};
const el = ref(null);

const { isSwiping, direction } = useSwipe(el, {
  onSwipe(e: TouchEvent) {},
  onSwipeEnd(e: TouchEvent, direction: UseSwipeDirection) {
    if (direction == "left") {
      mobile_collapsed.value = false;
    }
  },
});

const { currency } = storeToRefs(useCurrencyStore());
const ChangeCurrency = (e:Event) => {
  const val = (e.target as HTMLInputElement).value;
  currency.value = val;
  mobile_collapsed.value = false;
};
</script>
