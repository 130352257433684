<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useCurrencyStore } from "~/stores/currency";
const { locale } = useI18n();
const { setConfigCurrency } = useCurrencyStore();
const { currency } = storeToRefs(useCurrencyStore());
const switchLocalePath = useSwitchLocalePath();

const emits = defineEmits(["close"]);
const setCurrency = (c: string) => {
  setConfigCurrency(c);
  emits("close");
};
const route = useRoute();
const { object2Query } = useHelper();
const fullPath = (path: string) => {
  if (Object.keys(route.query).length) {
    return path + "?" + object2Query(route.query);
  }
  return path;
};
</script>
<template>
  <div class="p-4 bg-white dark:bg-neutral-900 flex flex-col gap-y-2">
    <div class="space-y-3 mb-6">
      <p class="font-semibold text-neutral-900 dark:text-white">
        {{ $t("language") }}
      </p>
      <div class="flex-center space-x-2">
        <NuxtLink
          :to="fullPath(switchLocalePath('en'))"
          @click="$emit('close')"
          :class="{
            'text-secondary-800  dark:text-secondary-800 hover:bg-secondary-100 border border-secondary-600 font-semibold bg-secondary-50':
              locale == 'en',
              'dark:text-white': locale != 'en',
          }"
          class="flex w-full  items-center space-x-3 rounded-xl px-3 py-1.5 focus:outline-none focus-visible:ring-2 disabled:bg-secondary-50 disabled:text-secondary-500 disabled:ring-1 disabled:ring-secondary-200"
        >
          <NuxtImg
            src="/icons/en.svg"
            class="w-5 mr-2"
            width="20"
            height="20"
            densities="1x"
            alt="english"
          />
          <span class="grow truncate text-left sm:text-sm">English</span>
          <span
            v-if="locale == 'en'"
            class="shrink-0 text-2xl icon-ms-outlined-done"
          >
            <Icon name="lets-icons:check-fill" size="24"></Icon>
          </span>
        </NuxtLink>
        <NuxtLink
          :to="fullPath(switchLocalePath('vi'))"
          @click="$emit('close')"
          :class="{
            'text-secondary-800  dark:text-secondary-800 hover:bg-secondary-100 border border-secondary-600 font-semibold bg-secondary-50':
              locale == 'vi',
              'dark:text-white': locale != 'vi',
          }"
          class="flex w-full items-center space-x-3 rounded-xl px-3 py-1.5 focus:outline-none focus-visible:ring-2 disabled:bg-secondary-50 disabled:text-secondary-500 disabled:ring-1 disabled:ring-secondary-200"
        >
          <NuxtImg
            src="/icons/vi.svg"
            class="w-5 mr-2"
            width="20"
            height="20"
            densities="1x"
            alt="english"
          />
          <span class="grow truncate text-left sm:text-sm">Tiếng việt</span>
          <span
            v-if="locale == 'vi'"
            class="shrink-0 text-2xl icon-ms-outlined-done"
          >
            <Icon name="lets-icons:check-fill" size="24"></Icon>
          </span>
        </NuxtLink>
      </div>
    </div>
    <div class="space-y-3 mb-6">
      <p class="font-semibold text-neutral-900 dark:text-white">
        {{ $t("currency") }}
      </p>
      <div class="flex-center space-x-2">
        <button
          @click="setCurrency('USD')"
          class="flex w-full cursor-pointer items-center space-x-3 rounded-xl px-3 py-1.5 focus:outline-none focus-visible:ring-2 disabled:bg-secondary-50 disabled:text-secondary-500 disabled:ring-1 disabled:ring-secondary-200"
          :class="{
            'text-secondary-800  dark:text-secondary-800 hover:bg-secondary-100 border border-secondary-600 bg-secondary-50':
              currency == 'USD',
            'dark:text-white': currency != 'USD',
          }"
        >
          <Icon name="tabler:currency-dollar" size="24" class="mr-2"> </Icon>
          <span class="grow truncate text-left sm:text-sm">USD</span>
          <span
            v-if="currency == 'USD'"
            class="shrink-0 text-2xl icon-ms-outlined-done"
          >
            <Icon name="lets-icons:check-fill" size="24"></Icon>
          </span>
        </button>
        <button
          :class="{
            'text-secondary-800  dark:text-secondary-800 hover:bg-secondary-100 border border-secondary-600 bg-secondary-50':
              currency == 'VND',
            'dark:text-white': currency != 'VND',
          }"
          @click="setCurrency('VND')"
          class="flex w-full cursor-pointer items-center space-x-3 rounded-xl px-3 py-1.5 focus:outline-none focus-visible:ring-2 disabled:bg-secondary-50 disabled:text-secondary-500 disabled:ring-1 disabled:ring-secondary-200"
        >
          <Icon name="tabler:currency-dong" size="24" class="mr-2"> </Icon>
          <span class="grow truncate text-left sm:text-sm">VNĐ</span>
          <span
            v-if="currency == 'VND'"
            class="shrink-0 text-2xl icon-ms-outlined-done"
          >
            <Icon name="lets-icons:check-fill" size="24"></Icon>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
