import { default as _91slug_93nVvYlKSmX8Meta } from "d:/App/Attractions/nuxt-app/pages/[type]/[slug].vue?macro=true";
import { default as indextDX55kR6huMeta } from "d:/App/Attractions/nuxt-app/pages/[type]/index.vue?macro=true";
import { default as about_45usDbFiSuKeXbMeta } from "d:/App/Attractions/nuxt-app/pages/about-us.vue?macro=true";
import { default as _91slug_93jC9OZFm9hgMeta } from "d:/App/Attractions/nuxt-app/pages/articles/[slug].vue?macro=true";
import { default as _91slug_93jfeaOBgP3KMeta } from "d:/App/Attractions/nuxt-app/pages/attractions/[slug].vue?macro=true";
import { default as _91username_931bYcz1dHAUMeta } from "d:/App/Attractions/nuxt-app/pages/author/[username].vue?macro=true";
import { default as index19bYqO8hFBMeta } from "d:/App/Attractions/nuxt-app/pages/blog/index.vue?macro=true";
import { default as contact_45usC5wqAYoNDxMeta } from "d:/App/Attractions/nuxt-app/pages/contact-us.vue?macro=true";
import { default as archivedcSgOJpX7GtMeta } from "d:/App/Attractions/nuxt-app/pages/dashboard/archived.vue?macro=true";
import { default as edit_45profileTrkYc81bN0Meta } from "d:/App/Attractions/nuxt-app/pages/dashboard/edit-profile.vue?macro=true";
import { default as indexawUj6xpiZpMeta } from "d:/App/Attractions/nuxt-app/pages/dashboard/index.vue?macro=true";
import { default as my_45articlesLRXYi21ZHfMeta } from "d:/App/Attractions/nuxt-app/pages/dashboard/my-articles.vue?macro=true";
import { default as submit_45postgbsaU4jUlNMeta } from "d:/App/Attractions/nuxt-app/pages/dashboard/submit-post.vue?macro=true";
import { default as forgot_45password1fN6rFIok1Meta } from "d:/App/Attractions/nuxt-app/pages/forgot-password.vue?macro=true";
import { default as indexOwESSHRfDHMeta } from "d:/App/Attractions/nuxt-app/pages/index.vue?macro=true";
import { default as list_45attractions1M6FcB6T5QMeta } from "d:/App/Attractions/nuxt-app/pages/list-attractions.vue?macro=true";
import { default as _91slug_93bjxIB3jAyrMeta } from "d:/App/Attractions/nuxt-app/pages/place-hotels/[slug].vue?macro=true";
import { default as _91slug_932eRC5lKWmBMeta } from "d:/App/Attractions/nuxt-app/pages/place/[slug].vue?macro=true";
import { default as places9YWmc5N2hwMeta } from "d:/App/Attractions/nuxt-app/pages/places.vue?macro=true";
import { default as privacy_45policyGk8tO8u89UMeta } from "d:/App/Attractions/nuxt-app/pages/privacy-policy.vue?macro=true";
import { default as redirect0HGSUA2mVPMeta } from "d:/App/Attractions/nuxt-app/pages/redirect.vue?macro=true";
import { default as search5yDSY4yYkTMeta } from "d:/App/Attractions/nuxt-app/pages/search.vue?macro=true";
import { default as sign_45inQzc7DSTGpAMeta } from "d:/App/Attractions/nuxt-app/pages/sign-in.vue?macro=true";
import { default as sign_45upr8ALWjum1MMeta } from "d:/App/Attractions/nuxt-app/pages/sign-up.vue?macro=true";
import { default as _91slug_93meHgDftJlAMeta } from "d:/App/Attractions/nuxt-app/pages/tag/[slug].vue?macro=true";
import { default as term_45of_45conditionsFRjcDgZJEEMeta } from "d:/App/Attractions/nuxt-app/pages/term-of-conditions.vue?macro=true";
import { default as _91slug_9346ISF56pdDMeta } from "d:/App/Attractions/nuxt-app/pages/topic/[slug].vue?macro=true";
import { default as verifyM2Ko1hxy69Meta } from "d:/App/Attractions/nuxt-app/pages/verify.vue?macro=true";
export default [
  {
    name: _91slug_93nVvYlKSmX8Meta?.name ?? "type-slug___en",
    path: _91slug_93nVvYlKSmX8Meta?.path ?? "/:type()/:slug()",
    meta: _91slug_93nVvYlKSmX8Meta || {},
    alias: _91slug_93nVvYlKSmX8Meta?.alias || [],
    redirect: _91slug_93nVvYlKSmX8Meta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nVvYlKSmX8Meta?.name ?? "type-slug___vi",
    path: _91slug_93nVvYlKSmX8Meta?.path ?? "/vi/:type()/:slug()",
    meta: _91slug_93nVvYlKSmX8Meta || {},
    alias: _91slug_93nVvYlKSmX8Meta?.alias || [],
    redirect: _91slug_93nVvYlKSmX8Meta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indextDX55kR6huMeta?.name ?? "type___en",
    path: indextDX55kR6huMeta?.path ?? "/:type()",
    meta: indextDX55kR6huMeta || {},
    alias: indextDX55kR6huMeta?.alias || [],
    redirect: indextDX55kR6huMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indextDX55kR6huMeta?.name ?? "type___vi",
    path: indextDX55kR6huMeta?.path ?? "/vi/:type()",
    meta: indextDX55kR6huMeta || {},
    alias: indextDX55kR6huMeta?.alias || [],
    redirect: indextDX55kR6huMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: about_45usDbFiSuKeXbMeta?.name ?? "about-us___en",
    path: about_45usDbFiSuKeXbMeta?.path ?? "/about-us",
    meta: about_45usDbFiSuKeXbMeta || {},
    alias: about_45usDbFiSuKeXbMeta?.alias || [],
    redirect: about_45usDbFiSuKeXbMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usDbFiSuKeXbMeta?.name ?? "about-us___vi",
    path: about_45usDbFiSuKeXbMeta?.path ?? "/vi/ve-chung-toi",
    meta: about_45usDbFiSuKeXbMeta || {},
    alias: about_45usDbFiSuKeXbMeta?.alias || [],
    redirect: about_45usDbFiSuKeXbMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jC9OZFm9hgMeta?.name ?? "articles-slug___en",
    path: _91slug_93jC9OZFm9hgMeta?.path ?? "/articles/:slug",
    meta: _91slug_93jC9OZFm9hgMeta || {},
    alias: _91slug_93jC9OZFm9hgMeta?.alias || [],
    redirect: _91slug_93jC9OZFm9hgMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jC9OZFm9hgMeta?.name ?? "articles-slug___vi",
    path: _91slug_93jC9OZFm9hgMeta?.path ?? "/vi/bai-viet/:slug",
    meta: _91slug_93jC9OZFm9hgMeta || {},
    alias: _91slug_93jC9OZFm9hgMeta?.alias || [],
    redirect: _91slug_93jC9OZFm9hgMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jfeaOBgP3KMeta?.name ?? "attractions-slug___en",
    path: _91slug_93jfeaOBgP3KMeta?.path ?? "/attractions/:slug",
    meta: _91slug_93jfeaOBgP3KMeta || {},
    alias: _91slug_93jfeaOBgP3KMeta?.alias || [],
    redirect: _91slug_93jfeaOBgP3KMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/attractions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jfeaOBgP3KMeta?.name ?? "attractions-slug___vi",
    path: _91slug_93jfeaOBgP3KMeta?.path ?? "/vi/diem-du-lich/:slug",
    meta: _91slug_93jfeaOBgP3KMeta || {},
    alias: _91slug_93jfeaOBgP3KMeta?.alias || [],
    redirect: _91slug_93jfeaOBgP3KMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/attractions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91username_931bYcz1dHAUMeta?.name ?? "author-username___en",
    path: _91username_931bYcz1dHAUMeta?.path ?? "/author/:username",
    meta: _91username_931bYcz1dHAUMeta || {},
    alias: _91username_931bYcz1dHAUMeta?.alias || [],
    redirect: _91username_931bYcz1dHAUMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/author/[username].vue").then(m => m.default || m)
  },
  {
    name: _91username_931bYcz1dHAUMeta?.name ?? "author-username___vi",
    path: _91username_931bYcz1dHAUMeta?.path ?? "/vi/tac-gia/:username",
    meta: _91username_931bYcz1dHAUMeta || {},
    alias: _91username_931bYcz1dHAUMeta?.alias || [],
    redirect: _91username_931bYcz1dHAUMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/author/[username].vue").then(m => m.default || m)
  },
  {
    name: index19bYqO8hFBMeta?.name ?? "blog___en",
    path: index19bYqO8hFBMeta?.path ?? "/blog",
    meta: index19bYqO8hFBMeta || {},
    alias: index19bYqO8hFBMeta?.alias || [],
    redirect: index19bYqO8hFBMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index19bYqO8hFBMeta?.name ?? "blog___vi",
    path: index19bYqO8hFBMeta?.path ?? "/vi/blog",
    meta: index19bYqO8hFBMeta || {},
    alias: index19bYqO8hFBMeta?.alias || [],
    redirect: index19bYqO8hFBMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contact_45usC5wqAYoNDxMeta?.name ?? "contact-us___en",
    path: contact_45usC5wqAYoNDxMeta?.path ?? "/contact-us",
    meta: contact_45usC5wqAYoNDxMeta || {},
    alias: contact_45usC5wqAYoNDxMeta?.alias || [],
    redirect: contact_45usC5wqAYoNDxMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45usC5wqAYoNDxMeta?.name ?? "contact-us___vi",
    path: contact_45usC5wqAYoNDxMeta?.path ?? "/vi/lien-he",
    meta: contact_45usC5wqAYoNDxMeta || {},
    alias: contact_45usC5wqAYoNDxMeta?.alias || [],
    redirect: contact_45usC5wqAYoNDxMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: archivedcSgOJpX7GtMeta?.name ?? "dashboard-archived___en",
    path: archivedcSgOJpX7GtMeta?.path ?? "/dashboard/archived",
    meta: archivedcSgOJpX7GtMeta || {},
    alias: archivedcSgOJpX7GtMeta?.alias || [],
    redirect: archivedcSgOJpX7GtMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/archived.vue").then(m => m.default || m)
  },
  {
    name: archivedcSgOJpX7GtMeta?.name ?? "dashboard-archived___vi",
    path: archivedcSgOJpX7GtMeta?.path ?? "/vi/bang-dieu-khien/luu-tru",
    meta: archivedcSgOJpX7GtMeta || {},
    alias: archivedcSgOJpX7GtMeta?.alias || [],
    redirect: archivedcSgOJpX7GtMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/archived.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileTrkYc81bN0Meta?.name ?? "dashboard-edit-profile___en",
    path: edit_45profileTrkYc81bN0Meta?.path ?? "/dashboard/edit-profile",
    meta: edit_45profileTrkYc81bN0Meta || {},
    alias: edit_45profileTrkYc81bN0Meta?.alias || [],
    redirect: edit_45profileTrkYc81bN0Meta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileTrkYc81bN0Meta?.name ?? "dashboard-edit-profile___vi",
    path: edit_45profileTrkYc81bN0Meta?.path ?? "/vi/bang-dieu-khien/chinh-sua-thong-tin",
    meta: edit_45profileTrkYc81bN0Meta || {},
    alias: edit_45profileTrkYc81bN0Meta?.alias || [],
    redirect: edit_45profileTrkYc81bN0Meta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: indexawUj6xpiZpMeta?.name ?? "dashboard___en",
    path: indexawUj6xpiZpMeta?.path ?? "/dashboard",
    meta: indexawUj6xpiZpMeta || {},
    alias: indexawUj6xpiZpMeta?.alias || [],
    redirect: indexawUj6xpiZpMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexawUj6xpiZpMeta?.name ?? "dashboard___vi",
    path: indexawUj6xpiZpMeta?.path ?? "/vi/dashboard",
    meta: indexawUj6xpiZpMeta || {},
    alias: indexawUj6xpiZpMeta?.alias || [],
    redirect: indexawUj6xpiZpMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: my_45articlesLRXYi21ZHfMeta?.name ?? "dashboard-my-articles___en",
    path: my_45articlesLRXYi21ZHfMeta?.path ?? "/dashboard/my-articles",
    meta: my_45articlesLRXYi21ZHfMeta || {},
    alias: my_45articlesLRXYi21ZHfMeta?.alias || [],
    redirect: my_45articlesLRXYi21ZHfMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/my-articles.vue").then(m => m.default || m)
  },
  {
    name: my_45articlesLRXYi21ZHfMeta?.name ?? "dashboard-my-articles___vi",
    path: my_45articlesLRXYi21ZHfMeta?.path ?? "/vi/bang-dieu-khien/danh-sach-bai-viet",
    meta: my_45articlesLRXYi21ZHfMeta || {},
    alias: my_45articlesLRXYi21ZHfMeta?.alias || [],
    redirect: my_45articlesLRXYi21ZHfMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/my-articles.vue").then(m => m.default || m)
  },
  {
    name: submit_45postgbsaU4jUlNMeta?.name ?? "dashboard-submit-post___en",
    path: submit_45postgbsaU4jUlNMeta?.path ?? "/dashboard/submit-post",
    meta: submit_45postgbsaU4jUlNMeta || {},
    alias: submit_45postgbsaU4jUlNMeta?.alias || [],
    redirect: submit_45postgbsaU4jUlNMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/submit-post.vue").then(m => m.default || m)
  },
  {
    name: submit_45postgbsaU4jUlNMeta?.name ?? "dashboard-submit-post___vi",
    path: submit_45postgbsaU4jUlNMeta?.path ?? "/vi/bang-dieu-khien/gui-bai-viet",
    meta: submit_45postgbsaU4jUlNMeta || {},
    alias: submit_45postgbsaU4jUlNMeta?.alias || [],
    redirect: submit_45postgbsaU4jUlNMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/dashboard/submit-post.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1fN6rFIok1Meta?.name ?? "forgot-password___en",
    path: forgot_45password1fN6rFIok1Meta?.path ?? "/forgot-password",
    meta: forgot_45password1fN6rFIok1Meta || {},
    alias: forgot_45password1fN6rFIok1Meta?.alias || [],
    redirect: forgot_45password1fN6rFIok1Meta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password1fN6rFIok1Meta?.name ?? "forgot-password___vi",
    path: forgot_45password1fN6rFIok1Meta?.path ?? "/vi/quen-mat-khau",
    meta: forgot_45password1fN6rFIok1Meta || {},
    alias: forgot_45password1fN6rFIok1Meta?.alias || [],
    redirect: forgot_45password1fN6rFIok1Meta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexOwESSHRfDHMeta?.name ?? "index___en",
    path: indexOwESSHRfDHMeta?.path ?? "/",
    meta: indexOwESSHRfDHMeta || {},
    alias: indexOwESSHRfDHMeta?.alias || [],
    redirect: indexOwESSHRfDHMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexOwESSHRfDHMeta?.name ?? "index___vi",
    path: indexOwESSHRfDHMeta?.path ?? "/vi",
    meta: indexOwESSHRfDHMeta || {},
    alias: indexOwESSHRfDHMeta?.alias || [],
    redirect: indexOwESSHRfDHMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: list_45attractions1M6FcB6T5QMeta?.name ?? "list-attractions___en",
    path: list_45attractions1M6FcB6T5QMeta?.path ?? "/list-attractions",
    meta: list_45attractions1M6FcB6T5QMeta || {},
    alias: list_45attractions1M6FcB6T5QMeta?.alias || [],
    redirect: list_45attractions1M6FcB6T5QMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/list-attractions.vue").then(m => m.default || m)
  },
  {
    name: list_45attractions1M6FcB6T5QMeta?.name ?? "list-attractions___vi",
    path: list_45attractions1M6FcB6T5QMeta?.path ?? "/vi/danh-sach-dia-diem-du-lich",
    meta: list_45attractions1M6FcB6T5QMeta || {},
    alias: list_45attractions1M6FcB6T5QMeta?.alias || [],
    redirect: list_45attractions1M6FcB6T5QMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/list-attractions.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bjxIB3jAyrMeta?.name ?? "place-hotels-slug___en",
    path: _91slug_93bjxIB3jAyrMeta?.path ?? "/place-hotels/:slug",
    meta: _91slug_93bjxIB3jAyrMeta || {},
    alias: _91slug_93bjxIB3jAyrMeta?.alias || [],
    redirect: _91slug_93bjxIB3jAyrMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/place-hotels/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93bjxIB3jAyrMeta?.name ?? "place-hotels-slug___vi",
    path: _91slug_93bjxIB3jAyrMeta?.path ?? "/vi/dia-diem-khach-san/:slug",
    meta: _91slug_93bjxIB3jAyrMeta || {},
    alias: _91slug_93bjxIB3jAyrMeta?.alias || [],
    redirect: _91slug_93bjxIB3jAyrMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/place-hotels/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932eRC5lKWmBMeta?.name ?? "place-slug___en",
    path: _91slug_932eRC5lKWmBMeta?.path ?? "/place/:slug",
    meta: _91slug_932eRC5lKWmBMeta || {},
    alias: _91slug_932eRC5lKWmBMeta?.alias || [],
    redirect: _91slug_932eRC5lKWmBMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/place/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_932eRC5lKWmBMeta?.name ?? "place-slug___vi",
    path: _91slug_932eRC5lKWmBMeta?.path ?? "/vi/dia-diem/:slug",
    meta: _91slug_932eRC5lKWmBMeta || {},
    alias: _91slug_932eRC5lKWmBMeta?.alias || [],
    redirect: _91slug_932eRC5lKWmBMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/place/[slug].vue").then(m => m.default || m)
  },
  {
    name: places9YWmc5N2hwMeta?.name ?? "places___en",
    path: places9YWmc5N2hwMeta?.path ?? "/places",
    meta: places9YWmc5N2hwMeta || {},
    alias: places9YWmc5N2hwMeta?.alias || [],
    redirect: places9YWmc5N2hwMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/places.vue").then(m => m.default || m)
  },
  {
    name: places9YWmc5N2hwMeta?.name ?? "places___vi",
    path: places9YWmc5N2hwMeta?.path ?? "/vi/danh-sach-dia-diem",
    meta: places9YWmc5N2hwMeta || {},
    alias: places9YWmc5N2hwMeta?.alias || [],
    redirect: places9YWmc5N2hwMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/places.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyGk8tO8u89UMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyGk8tO8u89UMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyGk8tO8u89UMeta || {},
    alias: privacy_45policyGk8tO8u89UMeta?.alias || [],
    redirect: privacy_45policyGk8tO8u89UMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyGk8tO8u89UMeta?.name ?? "privacy-policy___vi",
    path: privacy_45policyGk8tO8u89UMeta?.path ?? "/vi/chinh-sach-bao-mat",
    meta: privacy_45policyGk8tO8u89UMeta || {},
    alias: privacy_45policyGk8tO8u89UMeta?.alias || [],
    redirect: privacy_45policyGk8tO8u89UMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: redirect0HGSUA2mVPMeta?.name ?? "redirect___en",
    path: redirect0HGSUA2mVPMeta?.path ?? "/redirect",
    meta: redirect0HGSUA2mVPMeta || {},
    alias: redirect0HGSUA2mVPMeta?.alias || [],
    redirect: redirect0HGSUA2mVPMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect0HGSUA2mVPMeta?.name ?? "redirect___vi",
    path: redirect0HGSUA2mVPMeta?.path ?? "/vi/redirect",
    meta: redirect0HGSUA2mVPMeta || {},
    alias: redirect0HGSUA2mVPMeta?.alias || [],
    redirect: redirect0HGSUA2mVPMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: search5yDSY4yYkTMeta?.name ?? "search___en",
    path: search5yDSY4yYkTMeta?.path ?? "/search",
    meta: search5yDSY4yYkTMeta || {},
    alias: search5yDSY4yYkTMeta?.alias || [],
    redirect: search5yDSY4yYkTMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: search5yDSY4yYkTMeta?.name ?? "search___vi",
    path: search5yDSY4yYkTMeta?.path ?? "/vi/search",
    meta: search5yDSY4yYkTMeta || {},
    alias: search5yDSY4yYkTMeta?.alias || [],
    redirect: search5yDSY4yYkTMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: sign_45inQzc7DSTGpAMeta?.name ?? "sign-in___en",
    path: sign_45inQzc7DSTGpAMeta?.path ?? "/sign-in",
    meta: sign_45inQzc7DSTGpAMeta || {},
    alias: sign_45inQzc7DSTGpAMeta?.alias || [],
    redirect: sign_45inQzc7DSTGpAMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45inQzc7DSTGpAMeta?.name ?? "sign-in___vi",
    path: sign_45inQzc7DSTGpAMeta?.path ?? "/vi/dang-nhap",
    meta: sign_45inQzc7DSTGpAMeta || {},
    alias: sign_45inQzc7DSTGpAMeta?.alias || [],
    redirect: sign_45inQzc7DSTGpAMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upr8ALWjum1MMeta?.name ?? "sign-up___en",
    path: sign_45upr8ALWjum1MMeta?.path ?? "/sign-up",
    meta: sign_45upr8ALWjum1MMeta || {},
    alias: sign_45upr8ALWjum1MMeta?.alias || [],
    redirect: sign_45upr8ALWjum1MMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: sign_45upr8ALWjum1MMeta?.name ?? "sign-up___vi",
    path: sign_45upr8ALWjum1MMeta?.path ?? "/vi/dang-ky",
    meta: sign_45upr8ALWjum1MMeta || {},
    alias: sign_45upr8ALWjum1MMeta?.alias || [],
    redirect: sign_45upr8ALWjum1MMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93meHgDftJlAMeta?.name ?? "tag-slug___en",
    path: _91slug_93meHgDftJlAMeta?.path ?? "/tag/:slug()",
    meta: _91slug_93meHgDftJlAMeta || {},
    alias: _91slug_93meHgDftJlAMeta?.alias || [],
    redirect: _91slug_93meHgDftJlAMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93meHgDftJlAMeta?.name ?? "tag-slug___vi",
    path: _91slug_93meHgDftJlAMeta?.path ?? "/vi/tag/:slug()",
    meta: _91slug_93meHgDftJlAMeta || {},
    alias: _91slug_93meHgDftJlAMeta?.alias || [],
    redirect: _91slug_93meHgDftJlAMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: term_45of_45conditionsFRjcDgZJEEMeta?.name ?? "term-of-conditions___en",
    path: term_45of_45conditionsFRjcDgZJEEMeta?.path ?? "/term-of-conditions",
    meta: term_45of_45conditionsFRjcDgZJEEMeta || {},
    alias: term_45of_45conditionsFRjcDgZJEEMeta?.alias || [],
    redirect: term_45of_45conditionsFRjcDgZJEEMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/term-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: term_45of_45conditionsFRjcDgZJEEMeta?.name ?? "term-of-conditions___vi",
    path: term_45of_45conditionsFRjcDgZJEEMeta?.path ?? "/vi/dieu-khoan-dich-vu",
    meta: term_45of_45conditionsFRjcDgZJEEMeta || {},
    alias: term_45of_45conditionsFRjcDgZJEEMeta?.alias || [],
    redirect: term_45of_45conditionsFRjcDgZJEEMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/term-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9346ISF56pdDMeta?.name ?? "topic-slug___en",
    path: _91slug_9346ISF56pdDMeta?.path ?? "/topic/:slug",
    meta: _91slug_9346ISF56pdDMeta || {},
    alias: _91slug_9346ISF56pdDMeta?.alias || [],
    redirect: _91slug_9346ISF56pdDMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/topic/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9346ISF56pdDMeta?.name ?? "topic-slug___vi",
    path: _91slug_9346ISF56pdDMeta?.path ?? "/vi/chu-de/:slug",
    meta: _91slug_9346ISF56pdDMeta || {},
    alias: _91slug_9346ISF56pdDMeta?.alias || [],
    redirect: _91slug_9346ISF56pdDMeta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/topic/[slug].vue").then(m => m.default || m)
  },
  {
    name: verifyM2Ko1hxy69Meta?.name ?? "verify___en",
    path: verifyM2Ko1hxy69Meta?.path ?? "/verify",
    meta: verifyM2Ko1hxy69Meta || {},
    alias: verifyM2Ko1hxy69Meta?.alias || [],
    redirect: verifyM2Ko1hxy69Meta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyM2Ko1hxy69Meta?.name ?? "verify___vi",
    path: verifyM2Ko1hxy69Meta?.path ?? "/vi/thay-doi-mat-khau",
    meta: verifyM2Ko1hxy69Meta || {},
    alias: verifyM2Ko1hxy69Meta?.alias || [],
    redirect: verifyM2Ko1hxy69Meta?.redirect || undefined,
    component: () => import("d:/App/Attractions/nuxt-app/pages/verify.vue").then(m => m.default || m)
  }
]