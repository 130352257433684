import type { OpUnitType } from "dayjs";

export function useHelper() {
  const randomChoice = (arr: string[]): string | null => {
    const count: { [key: string]: number } = {};
    arr.forEach((item) => {
      if (count[item]) {
        count[item]++;
      } else {
        count[item] = 1;
      }
    });

    let maxCount = 0;
    let mostFrequentValues: string[] = [];
    for (let key in count) {
      if (count[key] > maxCount) {
        maxCount = count[key];
        mostFrequentValues = [key];
      } else if (count[key] === maxCount) {
        mostFrequentValues.push(key);
      }
    }

    if (mostFrequentValues.length === 0) {
      return null;
    }

    const randomIndex = Math.floor(Math.random() * mostFrequentValues.length);
    return mostFrequentValues[randomIndex];
  };
  const removeDiacritics = (input: string): string => {
    return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  const shareNavigator = () => {
    const title = document.querySelector("title")?.textContent;
    if (typeof navigator !== "undefined") {
      navigator.share({
        url: window.location.href,
        title: title + "",
      });
    }
  };
  const File2Base64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = function () {
        return resolve(reader.result);
      };
      reader.onerror = function (error) {
        return reject(error);
      };
    });
  };
  const removeSign = (str: string) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/Đ/g, "D");
    return str;
  };
  const storageDisk = (path: any) => {
    if (path) {
      const host = useRuntimeConfig().public.api_url;
      return host + "/storage/" + path;
    }
    return "";
  };
  const sqftToSqm = (sqft: number) => {
    const conversionRate = 0.092903;
    const sqm = sqft * conversionRate;
    return Math.round(sqm);
  };
  const object2Query = (options: Object = {}) => {
    var str = [];
    var values = Object.values(options);
    var keys = Object.keys(options);
    for (let u = 0; u < keys.length; u++) {
      str.push(keys[u] + "=" + values[u]);
    }
    return str.join("&");
  };
  const compareDayjs = (
    date1: string,
    date2: string,
    type: OpUnitType = "month"
  ) => {
    const dayjs = useDayjs();
    return dayjs(date1).isSame(dayjs(date2), type);
  };

  return {
    randomChoice,
    removeDiacritics,
    shareNavigator,
    File2Base64,
    removeSign,
    storageDisk,
    sqftToSqm,
    object2Query,
    compareDayjs,
    
  };
}
