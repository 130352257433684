<script lang="ts" setup>
import type { Place } from "~/types/place";

const localePath = useLocalePath();
const props = defineProps({
  places: {
    type: Array as () => Place[],
    default: [],
  },
});

const generateUrlHCM = computed(() => {
  const baseUrl = "/search";
  const params = new URLSearchParams({
    ss: "Ho Chi Minh City",
    group_adults: "2",
    no_rooms: "1",
    group_children: "0",
    dest_id: "-3730078",
    dest_type: "CITY",
  });

  const checkin = new Date();
  checkin.setDate(checkin.getDate() + 7);
  const checkout = new Date(checkin);
  checkout.setDate(checkout.getDate() + 1);
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  params.append("checkin", formatDate(checkin));
  params.append("checkout", formatDate(checkout));

  return `${baseUrl}?${params.toString()}`;
});
const generateUrlHN = computed(() => {
  const baseUrl = "/search";
  const params = new URLSearchParams({
    ss: "Hanoi",
    group_adults: "2",
    no_rooms: "1",
    group_children: "0",
    dest_id: "-3714993",
    dest_type: "CITY",
  });

  const checkin = new Date();
  checkin.setDate(checkin.getDate() + 7);
  const checkout = new Date(checkin);
  checkout.setDate(checkout.getDate() + 1);
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  params.append("checkin", formatDate(checkin));
  params.append("checkout", formatDate(checkout));

  return `${baseUrl}?${params.toString()}`;
});
</script>
<template>
  <header>
    <div
      class="container mx-auto flex items-center px-4 py-2 sm:px-6 sm:py-3 lg:px-8"
    >
      <ElementsLogo />
      <div class="flex w-full min-w-0 items-center justify-end">
        <div class="hidden shrink-0 lg:flex lg:items-center lg:space-x-4">
          <nav class="hidden md:flex md:items-center">
            <NuxtLink
              :to="generateUrlHN"
              class="hover:text-opacity-100 cursor-pointer  text-opacity-80 py-2.5 px-4 xl:px-5 rounded-full hover:bg-slate-100 dark:hover:bg-slate-800 inline-flex items-center text-sm text-gray-800 dark:text-slate-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              {{ $t("properties_in", { place: "Hà Nội" }) }}
            </NuxtLink>
            <NuxtLink
              :to="generateUrlHCM"
              class="hover:text-opacity-100  cursor-pointer text-opacity-80 py-2.5 px-4 xl:px-5 rounded-full hover:bg-slate-100 dark:hover:bg-slate-800 inline-flex items-center text-sm text-gray-800 dark:text-slate-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              {{ $t("properties_in", { place: $t('hcm_city') }) }}
            </NuxtLink>
            <div>
              <LayoutsPlaceMenu :places="places" />
            </div>
            <NuxtLink
              :to="localePath('/blog')"
              class="hover:text-opacity-100 text-opacity-80 py-2.5 px-4 xl:px-5 rounded-full hover:bg-slate-100 dark:hover:bg-slate-800 inline-flex items-center text-sm text-gray-800 dark:text-slate-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              Blog
            </NuxtLink>
          </nav>
          <V2HeaderConfig></V2HeaderConfig>
          <div class="hidden md:block">
            <LayoutsColorMode />
          </div>
        </div>
        <V2MobileBurgeButton></V2MobileBurgeButton>
      </div>
      <LayoutsMenuMobile :places="places"></LayoutsMenuMobile>
    </div>
  </header>
</template>
