<template>
  <li>
    <NuxtLink
      :to="localePath(path)" 
      @click.prevent="closeModal"
      class="flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 pl-3 text-neutral-900 dark:text-neutral-200 font-medium"
    >
      <span class="py-2.5 block w-full">{{ title }}</span>
    </NuxtLink>
  </li>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useConfigStore } from '~/stores/config';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  path: {
    type: String,
    required: true,
  },
  isPath: {
    type: Boolean,
    default: false,
  },
});
const localePath = useLocalePath();
const { mobile_collapsed } = storeToRefs(useConfigStore());

const closeModal = ()=>{
  mobile_collapsed.value = false
}
</script>
