<template>
  <li class="text-slate-900 dark:text-white">
    <button
      @click="open = !open"
      class="flex w-full items-center py-2.5 px-4 font-medium  tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
    >
      <slot name="title" />
      <span class="block flex-grow">
        <span class="flex justify-end flex-grow">
          <Icon
            name="solar:alt-arrow-right-outline"
            :class="{ 'rotate-90': !open,'-rotate-90': open }"
            class="ml-2 h-5 w-5 text-neutral-500 transition-all ease-in-out duration-200"
          />
        </span>
      </span>
    </button>
    <ul v-show="open" class="nav-mobile-sub-menu pl-6 pb-1 text-base">
      <slot name="menu-child" />
    </ul>
  </li>
</template>
<script lang="ts" setup>
const open = ref(false);
</script>
