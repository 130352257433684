<template>
  <li class="text-slate-900 dark:text-white">
    <NuxtLink
      :to="localePath(path)"
      @click.prevent="closeModal"
      class="flex w-full items-center py-2.5 px-4 font-medium tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
    >
      {{ title }}
    </NuxtLink>
  </li>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useConfigStore } from '~/stores/config';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  path: {
    type: String,
    required: true,
  },
  isPath: {
    type: Boolean,
    default: false,
  },
});
const { mobile_collapsed } = storeToRefs(useConfigStore());

const localePath = useLocalePath();
const closeModal = ()=>{
  mobile_collapsed.value = false
}
</script>
