<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

const button = ref<HTMLButtonElement>();

</script>
<template>
  <Popover v-slot="{ open }" class="relative hidden md:flex md:items-center">
    <PopoverButton as="template">
      <button
        ref="button"
        class="inline-flex items-center rounded-full py-2.5 px-4 xl:px-5 text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none focus-visible:ring-2"
      >
        <IconGlobe></IconGlobe>
        <span class="mx-1">/</span>
        <IconCurrency></IconCurrency>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
          :class="{
            '-rotate-180': open,
          }"
          class="text-opacity-70 ml-1 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          ></path>
        </svg>
      </button>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute z-[999] top-full ring-1 ring-neutral-200 dark:ring-neutral-700 rounded-2xl drop-shadow-md overflow-hidden right-0 max-w-sm w-96"
      >
      <V2ConfigMain @close="close"></V2ConfigMain>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
