import { joinURL } from "ufo";
function convertImageUrl(url) {
  return url.replace(/(\.\w+)$/, "-150x150$1");
}
export function getImage(src, { modifiers, baseURL } = {}, { options, $img }) {
  const { width, height, format, fit, s3, ...providerModifiers } = modifiers;
  const operations = [];
  // process modifiers
  const operationsString = operations.join(",");
  return {
    url: joinURL(baseURL, operationsString, src),
  };
}
