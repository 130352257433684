<template>
  <Popover v-slot="{ open }">
    <PopoverButton
      :class="{ 'text-opacity-80': !open }"
      class="hover:text-opacity-100 py-2.5 px-4 xl:px-5 rounded-full hover:bg-slate-100 dark:hover:bg-slate-800 inline-flex items-center text-sm text-gray-800 dark:text-slate-300 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
    >
      <div class="flex items-center">
        <span>🏛️ {{ $t("destinations") }}</span>
      </div>
      <Icon
        name="mdi:chevron-down"
        size="24"
        class="ml-1 h-4 w-4 transition ease-in-out duration-150"
        :class="{
          '-rotate-180': open,
        }"
      />
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute z-50 w-full mt-3.5 inset-x-0"
      >
        <div class="bg-white dark:bg-neutral-900 shadow-lg">
          <div class="container">
            <div
              class="flex items-center w-full flex-wrap text-sm px-4 pt-14 pb-4 gap-x-6 gap-y-8 border-t border-slate-200 dark:border-slate-700"
            >
              <template v-if="tab == 'places'">
                <NuxtLink
                  @click="close()"
                  v-for="place in places"
                  :to="localePath('/place/' + place.slug)"
                  class="flex flex-col items-center justify-center"
                >
                  <span
                    class="w-full h-20 group overflow-hidden rounded-lg transition-all ease-linear"
                  >
                    <NuxtImg
                      class="object-cover w-full h-full group-hover:scale-105 duration-1000 ease-in-out"
                      provider="glide"
                      loading="lazy"
                      placeholder="/icons/loading.svg"
                      width="640"
                      quality="80"
                      format="webp"
                      :src="place.thumbnail"
                    />
                  </span>
                  <span class="mt-2 font-semibold dark:text-white">{{ place.name }}</span>
                </NuxtLink>
              </template>

              <div class="w-full flex items-center justify-center">
                <button
                  @click="openExploreAll(close)"
                  class="h-10 border hover:bg-neutral-100 border-neutral-800 text-center flex items-center justify-center px-6 rounded-full font-semibold dark:border-neutral-50 dark:text-white dark:hover:text-neutral-800"
                >
                  {{ $t("explore_all") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import type { Place } from "~/types/place";
import type { Attraction } from "~/types/Attractions";

const localePath = useLocalePath();
const props = defineProps({
  places: {
    type: Array as () => Place[],
  },
  attractions: {
    type: Array as () => Attraction[],
  },
});
const tab = ref("places");
const openExploreAll = (close: any) => {
  if (tab.value == "places") {
    useRouter().push(localePath("places"));
  } else {
    useRouter().push(localePath("list-attractions"));
  }
  close();
};
</script>
<style lang="scss"></style>
