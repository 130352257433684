<script setup></script>
<template>
  <div class="flex flex-col py-8">
    <p class="text-base font-bold text-neutral-900 dark:text-white">{{ $t("our_partner") }}</p>
    <p class="mt-6 space-x-5">
      <NuxtImg
        class="inline-block h-4"
        height="16"
        src="/images/bookingcom-outline-logo.png"
        alt="Booking.com logo"
      />
      <NuxtImg
        class="inline-block h-4"
        height="16"
        src="/images/tripcom-outline-logo.png"
        alt="trip.com logo"
      />
    </p>
    <p class="mt-6 text-sm text-neutral-700 dark:text-neutral-200">
      {{ $t("partner_msg") }}
    </p>
  </div>
</template>
