export const localeCodes = ["en","vi"]

export const localeMessages = {
  "en": [{ key: "../locales/en.js", load: () => import("../locales/en.js" /* webpackChunkName: "lang_en_js_en_js" */) }],
  "vi": [{ key: "../locales/vi.js", load: () => import("../locales/vi.js" /* webpackChunkName: "lang_vi_js_vi_js" */) }],
}

export const additionalMessages = Object({"en":[],"vi":[],})

export const resolveNuxtI18nOptions = async (context) => {
  const nuxtI18nOptions = Object({})
  nuxtI18nOptions.experimental = Object({"jsTsFormatResource":false})
  nuxtI18nOptions.compilation = Object({"strictMessage":false,"escapeHtml":false})
 const vueI18nConfigLoader = async (loader) => {
            const config = await loader().then(r => r.default || r)
            return typeof config === 'object'
              ? config
              : typeof config === 'function'
                ? await config()
                : {}
          }
  const vueI18n = await vueI18nConfigLoader((() => import("\u0000@nuxtjs/i18n/__config__?target=../i18n.config.ts&hash=c045c512" /* webpackChunkName: "__i18n_config_ts_c045c512" */)))
  nuxtI18nOptions.vueI18n = vueI18n
  nuxtI18nOptions.locales = [Object({"name":"English","code":"en","iso":"en","file":"en.js","icon":"/icons/en.svg","hash":"f56ca911","type":"static"}),Object({"name":"Tiếng việt","code":"vi","iso":"vi-VN","file":"vi.js","icon":"/icons/vi.svg","hash":"bd4150e3","type":"static"})]
  nuxtI18nOptions.defaultLocale = "en"
  nuxtI18nOptions.defaultDirection = "ltr"
  nuxtI18nOptions.routesNameSeparator = "___"
  nuxtI18nOptions.trailingSlash = false
  nuxtI18nOptions.defaultLocaleRouteNameSuffix = "default"
  nuxtI18nOptions.strategy = "prefix_except_default"
  nuxtI18nOptions.lazy = true
  nuxtI18nOptions.langDir = "./locales"
  nuxtI18nOptions.rootRedirect = null
  nuxtI18nOptions.detectBrowserLanguage = false
  nuxtI18nOptions.differentDomains = false
  nuxtI18nOptions.baseUrl = "https://attractionsvietnam.com"
  nuxtI18nOptions.dynamicRouteParams = false
  nuxtI18nOptions.customRoutes = "config"
  nuxtI18nOptions.pages = Object({"forgot-password":Object({"en":"/forgot-password","vi":"/quen-mat-khau"}),"sign-in":Object({"en":"/sign-in","vi":"/dang-nhap"}),"sign-up":Object({"en":"/sign-up","vi":"/dang-ky"}),"articles/[slug]":Object({"en":"/articles/[slug]","vi":"/bai-viet/[slug]"}),"topic/[slug]":Object({"en":"/topic/[slug]","vi":"/chu-de/[slug]"}),"place/[slug]":Object({"en":"/place/[slug]","vi":"/dia-diem/[slug]"}),"place-hotels/[slug]":Object({"en":"/place-hotels/[slug]","vi":"/dia-diem-khach-san/[slug]"}),"attractions/[slug]":Object({"en":"/attractions/[slug]","vi":"/diem-du-lich/[slug]"}),"places":Object({"en":"/places","vi":"/danh-sach-dia-diem"}),"list-attractions":Object({"en":"/list-attractions","vi":"/danh-sach-dia-diem-du-lich"}),"author/[username]":Object({"en":"/author/[username]","vi":"/tac-gia/[username]"}),"contact-us":Object({"en":"/contact-us","vi":"/lien-he"}),"term-of-conditions":Object({"en":"/term-of-conditions","vi":"/dieu-khoan-dich-vu"}),"privacy-policy":Object({"en":"/privacy-policy","vi":"/chinh-sach-bao-mat"}),"verify":Object({"en":"/verify","vi":"/thay-doi-mat-khau"}),"dashboard/archived":Object({"en":"/dashboard/archived","vi":"/bang-dieu-khien/luu-tru"}),"dashboard/my-articles":Object({"en":"/dashboard/my-articles","vi":"/bang-dieu-khien/danh-sach-bai-viet"}),"dashboard/edit-profile":Object({"en":"/dashboard/edit-profile","vi":"/bang-dieu-khien/chinh-sua-thong-tin"}),"dashboard/submit-post":Object({"en":"/dashboard/submit-post","vi":"/bang-dieu-khien/gui-bai-viet"}),"about-us":Object({"en":"/about-us","vi":"/ve-chung-toi"})})
  nuxtI18nOptions.skipSettingLocaleOnNavigate = false
  nuxtI18nOptions.types = "composition"
  nuxtI18nOptions.debug = false
  return nuxtI18nOptions
}

export const nuxtI18nOptionsDefault = Object({experimental: Object({"jsTsFormatResource":false}),compilation: Object({"strictMessage":true,"escapeHtml":false}),vueI18n: "",locales: [],defaultLocale: "",defaultDirection: "ltr",routesNameSeparator: "___",trailingSlash: false,defaultLocaleRouteNameSuffix: "default",strategy: "prefix_except_default",lazy: false,langDir: null,rootRedirect: null,detectBrowserLanguage: Object({"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true}),differentDomains: false,baseUrl: "",dynamicRouteParams: false,customRoutes: "page",pages: Object({}),skipSettingLocaleOnNavigate: false,types: "composition",debug: false})

export const nuxtI18nInternalOptions = Object({__normalizedLocales: [Object({"name":"English","code":"en","iso":"en","file":"en.js","icon":"/icons/en.svg","hash":"f56ca911","type":"static"}),Object({"name":"Tiếng việt","code":"vi","iso":"vi-VN","file":"vi.js","icon":"/icons/vi.svg","hash":"bd4150e3","type":"static"})]})
export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const isSSG = false
