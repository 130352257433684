<template>
  <div class="dark:bg-neutral-900  dark:text-neutral-200">
    <V2Header :places="layoutData?.places"></V2Header>
    <div class="container my-12">
      <div class="flex items-center flex-col justify-center gap-y-6">
        <NuxtImg src="/images/404.png" width="300" format="webp" />
        <div class="text-center text-3xl font-semibold">
          {{ error?.message }}
        </div>
        <button @click="goBack"
          class="flex-shrink-0 relative inline-flex items-center justify-center rounded-lg transition-colors border-transparent bg-secondary-500 hover:bg-secondary-600 text-neutral-50 text-sm sm:text-base font-medium py-2 px-4 sm:px-6 bg-gradient-to-bl from-secondary-500 to-teal-600 sm:h-auto sm:w-auto h-10 w-10">
          {{ $t('go_to_homepage') }}
        </button>
      </div>
    </div>
    <LayoutsFooter :places="layoutData?.places" :hotels="layoutData?.hotels" />
  </div>
</template>
<script lang="ts" setup>
import "@/assets/css/main.scss";
import { storeToRefs } from "pinia";
import { useLayoutStore } from "./stores/layout";
import type { ResponseDataLayout } from "./types/Layout";
const props = defineProps({
  error: Object,
});
const { layoutData } = storeToRefs(useLayoutStore());
const { locale } = useI18n();
const { data } = await useAsyncData(async () => {
  if (!layoutData.value) {
    const res: ResponseDataLayout = await $fetch("/api/layout/data", {
      body: {
        locale: locale.value,
      },
      method: "post",
    });
    return res;
  }
  return layoutData.value;
});

if (data.value) {
  layoutData.value = data as unknown as ResponseDataLayout;
}
const router = useRouter();

const goBack = () => {
  router.back();
};
useHead({
  title: props.error?.message,
  meta: [{ name: "description", content: "" }],
});
</script>
