import type { UseFetchOptions } from "nuxt/app";
import { useRequestHeaders } from "nuxt/app";

export function useApiFetch<T>(path: string, options: UseFetchOptions<T> = {}) {
  let headers: any = {};

  const runtimeConfig = useRuntimeConfig();
  const token = useCookie(runtimeConfig.public.auth_cookie_name);
 
  if (token.value) {
    headers["Authorization"] = "Bearer "+token.value as string;
  }

  if (process.server) {
    headers = {
      ...headers,
      ...useRequestHeaders(["cookie"]),
    };
  }
  
  return useFetch(path, {
    credentials: "include",
    watch: false,
    ...options,
    headers: {
      ...headers,
      ...options?.headers,
    },
  });
}
