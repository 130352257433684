import { defineStore } from "pinia";
import { User } from "~/types/User";
import { useApiFetch } from "~/composables/apiFetch";
import { LoginCredential } from "~/types/LoginCredential";
import { RegisterCredential } from "~/types/RegisterCredential";

interface SignResponse {
  access_token: string;
  token_type: string;
  status: number;
  message?: string;
}
interface SocialiteCredential {
  access_token: string;
  service: string;
}
interface UserResponseToken extends User {
  access_token: string;
}
export const useAuthStore = defineStore("auth", () => {
  const user = ref<User | null>(null);
  const runtimeConfig = useRuntimeConfig();
  const loginForm = ref<LoginCredential>({
    email: "",
    password: "",
  });

  const registerForm = ref<RegisterCredential>({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
  });

  const isLoggedIn = computed(() => !!user.value);

  async function logout() {
    try {
      await useApiFetch("/api/user/logout", { method: "POST" });
      useCookie(runtimeConfig.public.auth_cookie_name).value = "";
      user.value = null;
      if (useRoute().path.includes("/dashboard")) {
        navigateTo("/sign-in");
      } else {
        navigateTo(useRoute().path);
      }
    } catch (error) {}
  }

  async function fetchUser(options = {}) {
    const { data, error } = await useApiFetch("/api/user", options);
    user.value = data.value as User;
  }

  async function login(credentials: LoginCredential) {
    var error = null;
    try {
      const l = await useApiFetch("/api/auth/login", {
        method: "POST",
        body: credentials,
      });
      error = l.error.value?.data;
      const data = l.data.value as SignResponse;
      if (data && data.access_token) {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        const access_token = useCookie(runtimeConfig.public.auth_cookie_name, {
          expires: date,
        });
        access_token.value = data.access_token;

        await fetchUser({
          headers: {
            Authorization: data.token_type + " " + data.access_token,
          },
        });
        return login;
      }
    } catch (e) {}
    return Promise.reject(error);
  }

  async function register(info: RegisterCredential) {
    var error = null;
    try {
      const l = await useApiFetch("/api/auth/register", {
        method: "POST",
        body: info,
      });
      error = l.error.value?.data;
      const data = l.data.value as SignResponse;
      if (data && data.access_token) {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        const access_token = useCookie(runtimeConfig.public.auth_cookie_name, {
          expires: date,
        });
        access_token.value = data.access_token;

        await fetchUser({
          headers: {
            Authorization: data.token_type + " " + data.access_token,
          },
        });
        return register;
      }
    } catch (e) {}
    return Promise.reject(error);
  }

  async function signSocialite(info: SocialiteCredential) {
    var error = null;
    try {
      const l = await useApiFetch("/api/auth/socialite", {
        method: "POST",
        body: info,
      });
      error = l.error.value?.data;
      const data = l.data.value as UserResponseToken;
      if (data && data.access_token) {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        const access_token = useCookie(runtimeConfig.public.auth_cookie_name, {
          expires: date,
        });
        access_token.value = data.access_token;
        user.value = data;
        return signSocialite;
      }
    } catch (e) {}
    return Promise.reject(error);
  }

  return {
    user,
    login,
    isLoggedIn,
    fetchUser,
    logout,
    register,
    loginForm,
    registerForm,
    signSocialite
  };
});
