import { defineStore } from "pinia";

export const useConfigStore = defineStore("config", {
  state: () => {
    return {
      dialog_search:false,
      search_modal: false,
      auth_modal: false,
      masonry_modal: false,
      lightbox_modal: false,
      position_lightbox: 0,
      auth_step: "",
      mobile_collapsed:false,
      header_display:true,
      review_modal:false,
      booking_modal:false,
      report_modal:false,
      popstateMasonryInitialized :false,
      popstateInitialized :false,
      stateStopSlideChanged:false,
      navigation_entity:false,
      mobile_tools_currency:false,
      mobile_tools_filter:false,
      mobile_tools_sort:false,
    };
  },
  getters: {},
  actions: {},
});
