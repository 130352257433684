<script setup></script>
<template>
  <h2 class="font-bold text-lg text-neutral-900 dark:text-neutral-200">
    {{$t('help')}}
  </h2>
  <ul class="mt-6 text-sm space-y-4">
    <li>
      <NuxtLink
        class="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        :to="localePath('/sign-in')"
      >
        {{ $t("sign_in") }}
      </NuxtLink>
    </li>
    <li>
      <NuxtLink
        class="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        :to="localePath('/sign-up')"
      >
        {{ $t("sign_up") }}
      </NuxtLink>
    </li>
    <li>
      <NuxtLink
        class="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        :to="localePath('/contact-us')"
      >
        {{ $t("contact_us") }}
      </NuxtLink>
    </li>
    <li>
      <NuxtLink
        class="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        :to="localePath('/about-us')"
      >
        {{ $t("about_us") }}
      </NuxtLink>
    </li>
    <li>
      <NuxtLink
        class="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        :to="localePath('/blog')"
      >
        Blog
      </NuxtLink>
    </li>
    <li>
      <NuxtLink
        class="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        :to="localePath('/privacy-policy')"
      >
        {{ $t("privacy") }}
      </NuxtLink>
    </li>
    <li>
      <NuxtLink
        class="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
        :to="localePath('/term-of-conditions')"
      >
        {{ $t("terms_of_conditions") }}
      </NuxtLink>
    </li>
  </ul>
</template>
