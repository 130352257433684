<template>
  <button
    @click="ToggleColorMode()"
    class="self-center text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center"
  >
    <span class="sr-only">Switch mode</span>
    <Icon name="solar:sun-2-outline" v-if="mode === 'light'" size="26" />
    <Icon name="solar:moon-sleep-outline" v-else size="24" /> 
  </button>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useColorStore } from "~/stores/color";

const { mode } = storeToRefs(useColorStore());
const ToggleColorMode = () => {
  if (mode.value == "light") {
    mode.value = "dark";
  } else {
    mode.value = "light";
  }
};
</script>
