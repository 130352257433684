import { defineStore } from "pinia";
import type { ResponseDataLayout } from "~/types/Layout";
const layoutData: ResponseDataLayout | null = null;
const s: {
  layoutData: ResponseDataLayout | null;
} = {
  layoutData: layoutData,
};
export const useLayoutStore = defineStore("layout", {
  state: () => {
    return s;
  },
  getters: {},
  actions: {},
});
