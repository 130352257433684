import { defineStore } from "pinia";

export const useColorStore = defineStore("color", {
  state: () => {
    return {
      mode: "light",
    };
  },
  getters: {},
  actions: {},
});
