import { storeToRefs } from "pinia";
import { useColorStore } from "~/stores/color";

export default defineNuxtPlugin(async (nuxtApp) => {
  const { mode } = storeToRefs(useColorStore());
  if (typeof localStorage !== "undefined") {
    const m = localStorage.getItem("color_mode");
    if (m) {
      mode.value = m;
    }
  } else {
    if (typeof window !== "undefined") {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        mode.value = "dark";
      }
    }
  }
  if (typeof document !== "undefined") {
    var oldValue = "light";
    if (mode.value == "light") {
      oldValue = "dark";
    }
    document.querySelector("html")?.classList.remove(oldValue);
    document.querySelector("html")?.classList.add(mode.value);
  }
});
