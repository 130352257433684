import revive_payload_client_4sVQNw7RlN from "d:/App/Attractions/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "d:/App/Attractions/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "d:/App/Attractions/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "d:/App/Attractions/nuxt-app/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "d:/App/Attractions/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "d:/App/Attractions/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "d:/App/Attractions/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "d:/App/Attractions/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3tYSXut6Zj from "D:/App/Attractions/nuxt-app/node_modules/nuxt3-lenis/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "d:/App/Attractions/nuxt-app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_t2GMTTFnMT from "d:/App/Attractions/nuxt-app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import nuxt_plugin_03YjkxYbK5 from "d:/App/Attractions/nuxt-app/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import pwa_client_Eorgdr12jA from "d:/App/Attractions/nuxt-app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_Fspu1Dj02B from "d:/App/Attractions/nuxt-app/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import plugin_eskviOYyLt from "d:/App/Attractions/nuxt-app/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "d:/App/Attractions/nuxt-app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import composition_sLxaNGmlSL from "d:/App/Attractions/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "d:/App/Attractions/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "d:/App/Attractions/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import colorMode_client_1iMqoQQdUl from "d:/App/Attractions/nuxt-app/plugins/colorMode.client.ts";
import currency_client_3avUExe9mM from "d:/App/Attractions/nuxt-app/plugins/currency.client.ts";
import form_client_TkIylfZzkE from "d:/App/Attractions/nuxt-app/plugins/form.client.ts";
import loadUser_LsqsWAQztf from "d:/App/Attractions/nuxt-app/plugins/loadUser.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_3tYSXut6Zj,
  plugin_client_LcKgStRyi6,
  plugin_t2GMTTFnMT,
  nuxt_plugin_03YjkxYbK5,
  pwa_client_Eorgdr12jA,
  i18n_Fspu1Dj02B,
  plugin_eskviOYyLt,
  plugin_8SbxDRbG6Y,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  colorMode_client_1iMqoQQdUl,
  currency_client_3avUExe9mM,
  form_client_TkIylfZzkE,
  loadUser_LsqsWAQztf
]