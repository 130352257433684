import { defineStore } from "pinia";

export const useCurrencyStore = defineStore("currency", () => {
  const currency = ref("VND");
  const currency_code = ref("₫");
  watch(currency, (v) => {
    currency_code.value = v == "USD" ? "$" : "₫";
  });
  const exchange = ref(25420);
  const actionExchange = (price: string) => {
    price = price.replace("$", "");
    if (currency.value == "USD") {
      return price;
    }
    return (parseFloat(price) * exchange.value).toLocaleString();
  };
  const runtimeConfig = useRuntimeConfig();
  const getConfigCurrency = () => {
    const cookie_gmt = useCookie(runtimeConfig.public.currency_name);
    if (cookie_gmt.value) {
      currency.value = cookie_gmt.value;
    }
  };
  const setConfigCurrency = (c: string) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    const currency_cookie = useCookie(runtimeConfig.public.currency_name, {
      expires: date,
    });
    currency_cookie.value = c;
    currency.value = c;
  };
  return {
    currency,
    currency_code,
    exchange,
    getConfigCurrency,
    setConfigCurrency,
    actionExchange,
  };
});
