import { defineStore, storeToRefs } from "pinia";
import type { Accommodation } from "~/types/Nest";
import { useCurrencyStore } from "./currency";
interface Month {
  month: string;
  year: string;
  startDate: string;
}
interface NightLong {
  label: string;
  value: string;
}
interface FormBooking {
  type: string;
  datetime: any;
  adults: number;
  children: number;
  rooms: number;
  ltfd_str: string;
  ltfd: string;
  ageChildren: any[];
  flex_window: number;
  location: Accommodation | null;
}
function isDateExpired(dateString: string) {
  const inputDate = new Date(dateString);
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  inputDate.setHours(0, 0, 0, 0);
  if (inputDate < now) {
    return true;
  } else {
    return false;
  }
}
export const useBookingStore = defineStore("booking", () => {
  const form = ref<FormBooking>({
    type: "calendar",
    datetime: [],
    adults: 2,
    children: 0,
    rooms: 1,
    ltfd_str: "",
    ltfd: "",
    ageChildren: [],
    flex_window: 0,
    location: null,
  });
  const flexible = ref<{
    how_long: string;
    month_selected: Month[];
  }>({
    how_long: "weekend",
    month_selected: [],
  });
  const { object2Query, compareDayjs } = useHelper();
  const tab = ref("calendar");
  const query_string = ref("");

  const selectDate = (month: Month) => {
    var f = false;
    for (let i = 0; i < flexible.value.month_selected.length; i++) {
      if (
        compareDayjs(
          flexible.value.month_selected[i].startDate,
          month.startDate
        )
      ) {
        f = true;
        break;
      }
      // if (flexible.value.month_selected[i].month == month.month) {
      //     f = true;
      //     break;
      // }
    }
    if (f) {
      flexible.value.month_selected = flexible.value.month_selected.filter(
        (item) => !compareDayjs(item.startDate, month.startDate)
      );
    } else {
      flexible.value.month_selected.push(month);
    }
  };

  const saveData = () => {
    setTimeout(() => {
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("calendar", JSON.stringify(form.value));
        localStorage.setItem("flexible", JSON.stringify(flexible.value));
      }
    }, 0);
  };
  const initData = () => {
    if (typeof localStorage !== "undefined") {
      const calendar = localStorage.getItem("calendar");
      const flex = localStorage.getItem("flexible");
      if (calendar) {
        form.value = JSON.parse(calendar);
      }
      if (flex) {
        flexible.value = JSON.parse(flex);
      }
      tab.value = form.value.type;
      const destinationsSelectedStr = localStorage.getItem(
        "DestinationsSelected"
      );
      if (destinationsSelectedStr) {
        destinationsSelected.value = JSON.parse(destinationsSelectedStr);
      }
      const dayjs = useDayjs();
      try {
        if (form.value.datetime && form.value.datetime.length) {
          if (form.value.datetime[1] && isDateExpired(form.value.datetime[1])) {
            form.value.datetime[0] = dayjs().add(1, "day").format("YYYY-MM-DD");
            form.value.datetime[1] = dayjs().add(2, "day").format("YYYY-MM-DD");
            return;
          }
          if (form.value.datetime[0] && isDateExpired(form.value.datetime[0])) {
            form.value.datetime[0] = dayjs().format("YYYY-MM-DD");
          }
        } else {
          form.value.datetime[0] = dayjs().add(1, "day").format("YYYY-MM-DD");
          form.value.datetime[1] = dayjs().add(2, "day").format("YYYY-MM-DD");
        }
      } catch (e) {}
    }
    console.log("init", form.value);
  };

  const filter = ref<any>({
    sort: "popularity",
    travelPurpose: 2,
  });

  const selectedFiltersObj = ref<any>({
    min: null,
    max: null,
    options: [],
  });
  const { currency } = storeToRefs(useCurrencyStore());
  const querselectedFiltersQuery = ref("");
  watch(
    selectedFiltersObj,
    useDebounceFn((val) => {
      var queryArr = [];
      if (val.min || val.max) {
        var i = val.min ?? "min";
        var a = val.max ?? "max";
        queryArr.push(`price=${currency.value}-${i}-${a}-1`);
      }
      queryArr = [...queryArr, ...val.options];
      querselectedFiltersQuery.value = queryArr.join(";");
    }, 500),
    {
      deep: true,
    }
  );

  const destinationsSelected = ref<Accommodation[]>([]);

  watch(
    destinationsSelected,
    useDebounceFn(() => {
      if (typeof localStorage !== "undefined") {
        localStorage.setItem(
          "DestinationsSelected",
          JSON.stringify(destinationsSelected.value)
        );
      }
    }, 600),
    {
      deep: true,
    }
  );

  const makeQueryString = () => {
    var queryObject: any = {
      group_adults: form.value.adults,
      no_rooms: form.value.rooms,
      group_children: form.value.children,
    };
    if (form.value.datetime.length) {
      queryObject.checkin = form.value.datetime[0];
      queryObject.checkout = form.value.datetime[1];
    }
    if (form.value.type == "flexible") {
      if (form.value.ltfd) {
        //checkin=2024-01-16&checkout=2024-01-17&group_adults=2&no_rooms=1&group_children=0
        queryObject.ltfd = encodeURIComponent(form.value.ltfd);
      }
    } else {
      queryObject.flex_window = form.value.flex_window ?? 0;
    }
    var qstr = object2Query(queryObject);
    var age = [];
    if (form.value.children) {
      for (let i = 0; i < form.value.ageChildren.length; i++) {
        age.push("age=" + form.value.ageChildren[i]);
      }
    }

    if (age.length) {
      query_string.value = qstr + "&" + age.join("&");
    } else {
      query_string.value = qstr;
    }

    if (form.value.location) {
      query_string.value = `ss=${form.value.location.title}&dest_id=${form.value.location.code}&dest_type=${form.value.location.type}&${query_string.value}`;
      if (form.value.location.lat && form.value.location.lng) {
        query_string.value = `${query_string.value}&lat=${form.value.location.lat}&lng=${form.value.location.lng}`;
      }
    }

    if (typeof localStorage !== "undefined") {
      localStorage.setItem("query_booking", query_string.value);
    }
  };
  return {
    form,
    tab,
    flexible,
    saveData,
    query_string,
    selectDate,
    initData,
    filter,
    selectedFiltersObj,
    querselectedFiltersQuery,
    destinationsSelected,
    makeQueryString,
  };
});
