<script></script>
<template>
  <div
    class="flex flex-col justify-between sm:col-span-3 xl:col-span-2 xl:col-start-4"
  >
    <div>
      <p class="font-bold text-neutral-900 sm:text-lg dark:text-neutral-200">
        {{ $t('subscribe') }}
      </p>
      <p
        class="mt-2 text-sm text-neutral-700 dark:text-neutral-200 sm:text-base xl:text-sm"
      >
        {{ $t("subscribe_msg") }}
      </p>
      <form
        class="mt-6 flex flex-col space-y-3 2xl:flex-row sm:space-x-3 sm:space-y-0"
      >
        <input
          type="email"
          :placeholder="$t('homepage.enter_email')"
          class="form-input grow rounded-xl border-neutral-100 px-5 py-3.5 text-neutral-600 hover:bg-neutral-50/50 dark:hover:bg-neutral-100 focus:border-secondary-500/50 focus:outline-none focus-visible:ring-2 focus-visible:ring-secondary-500/50 disabled:bg-neutral-50/50"
          required=""
          value=""
        />
        <button
          type="submit"
          class="inline-flex select-none items-center justify-center font-bold focus:outline-none focus-visible:ring-2 focus-visible:ring-secondary-200 bg-secondary-600 text-white hover:bg-secondary-500 focus-visible:bg-secondary-500 active:bg-secondary-600 disabled:bg-secondary-100 text-base rounded-xl px-2 py-3.5"
        >
          {{ $t("subscribe") }}
        </button>
      </form>
    </div>
    <V2FooterPartner></V2FooterPartner>
  </div>
</template>
